import Enums from "../../scripts/enums.js";
import Moment from "moment";
import Common from "../../scripts/common.js";

export default {
    state: {
        ordersToAuthorize: [],
        currentOrderMedicalNecessityIndex: 4,
        currentOrderToAuthorize: null,
        previousGeneTypeState: null
    },
    getters: {
        getPreviousGeneTypeState: state => {
            return state.previousGeneTypeState;
        },
        getCurrentOrderId: state => {
            return state.currentOrderToAuthorize.orderId;
        },
        getOrdersToAuthorize: state => {
            return state.ordersToAuthorize;
        },
        getNumberOfOrdersToAuthorize: state => {
            return state.ordersToAuthorize.length;
        },
        getCurrentOrderToAuthorize: state => {
            return state.currentOrderToAuthorize;
        },
        getCurrentOrderPatientName: state => {
            return state.currentOrderToAuthorize ? state.currentOrderToAuthorize.patientFirstName + " " + state.currentOrderToAuthorize.patientLastName : "";
        },
        getCurrentOrderPatientEnteredName: state => {
            return state.currentOrderToAuthorize ? state.currentOrderToAuthorize.patientEnteredFirstName + " " + state.currentOrderToAuthorize.patientEnteredLastName : "";
        },
        getCurrentOrderPatientDob: state => {
            return state.currentOrderToAuthorize ? Moment(state.currentOrderToAuthorize.patientDOB).format("M/D/YYYY") : "";
        },
        getCurrentOrderPatientEnteredDob: state => {
            return state.currentOrderToAuthorize ? Moment(state.currentOrderToAuthorize.patientEnteredDOB).format("M/D/YYYY") : "";
        },
        getCurrentOrderIsMedicare: state => {
            return state.currentOrderToAuthorize ? state.currentOrderToAuthorize.isMedicare : false;
        },
        getCurrentOrderIsPatientEntered: state => {
            return state.currentOrderToAuthorize ? state.currentOrderToAuthorize.hasPatientData : false;
        },
        getCurrentOrderIsNotPatientMatched: state => {
            if (state.currentOrderToAuthorize) {
                return state.currentOrderToAuthorize.hasMismatchedName || state.currentOrderToAuthorize.hasMismatchedDOB;
            } else {
                return false;
            }
        },
        getCurrentOrderHasMismatchedName: state => {
            return state.currentOrderToAuthorize.hasMismatchedName;
        },
        getCurrentOrderHasMismatchedDob: state => {
            return state.currentOrderToAuthorize.hasMismatchedDOB;
        },
        getDsm5SufferFromDepression: state => {
            const path = ["currentOrderToAuthorize", "psych", "orderLineItemMedicalNecessityDomainModel", "groups", 0, "items", 0, "radioButtonInputValue"];
            const value = Common.getJsonPathValue(path, state);
            return value || false;
        },
        getDepressiveMood: state => {
            const path = ["currentOrderToAuthorize", "psych", "orderLineItemMedicalNecessityDomainModel", "groups", 1, "items", 0, "selected"];
            const value = Common.getJsonPathValue(path, state);
            return value || false;
        },
        getDiminshedInterestActivities: state => {
            const path = ["currentOrderToAuthorize", "psych", "orderLineItemMedicalNecessityDomainModel", "groups", 1, "items", 1, "selected"];
            const value = Common.getJsonPathValue(path, state);
            return value || false;
        },
        getSignficatnWeightLoss: state => {
            const path = ["currentOrderToAuthorize", "psych", "orderLineItemMedicalNecessityDomainModel", "groups", 1, "items", 2, "selected"];
            const value = Common.getJsonPathValue(path, state);
            return value || false;
        },
        getInsomnia: state => {
            const path = ["currentOrderToAuthorize", "psych", "orderLineItemMedicalNecessityDomainModel", "groups", 1, "items", 3, "selected"];
            const value = Common.getJsonPathValue(path, state);
            return value || false;
        },
        getPsychomotor: state => {
            const path = ["currentOrderToAuthorize", "psych", "orderLineItemMedicalNecessityDomainModel", "groups", 1, "items", 4, "selected"];
            const value = Common.getJsonPathValue(path, state);
            return value || false;
        },
        getFatigue: state => {
            const path = ["currentOrderToAuthorize", "psych", "orderLineItemMedicalNecessityDomainModel", "groups", 1, "items", 5, "selected"];
            const value = Common.getJsonPathValue(path, state);
            return value || false;
        },
        getFeelingWorthless: state => {
            const path = ["currentOrderToAuthorize", "psych", "orderLineItemMedicalNecessityDomainModel", "groups", 1, "items", 6, "selected"];
            const value = Common.getJsonPathValue(path, state);
            return value || false;
        },
        getDimenishedConcentration: state => {
            const path = ["currentOrderToAuthorize", "psych", "orderLineItemMedicalNecessityDomainModel", "groups", 1, "items", 7, "selected"];
            const value = Common.getJsonPathValue(path, state);
            return value || false;
        },
        getRecurrentThoughtOfDeath: state => {
            const path = ["currentOrderToAuthorize", "psych", "orderLineItemMedicalNecessityDomainModel", "groups", 1, "items", 8, "selected"];
            const value = Common.getJsonPathValue(path, state);
            return value || false;
        },
        getMildSeverity: state => {
            if (state.currentOrderToAuthorize) {
                const mildId = state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[2].items[0].medicalNecessityQuestionGroupItemId;
                const selected = state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[2].selectedItem;

                return mildId === selected;
            } else {
                return false;
            }
        },
        getModerateSeverity: state => {
            if (state.currentOrderToAuthorize) {
                const moderateId = state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[2].items[1].medicalNecessityQuestionGroupItemId;
                const selected = state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[2].selectedItem;

                return moderateId === selected;
            } else {
                return false;
            }
        },
        getSevereSeverity: state => {
            if (state.currentOrderToAuthorize) {
                const severeId = state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[2].items[2].medicalNecessityQuestionGroupItemId;
                const selected = state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[2].selectedItem;

                return severeId === selected;
            } else {
                return false;
            }
        },
        getCurrentSeverityValue: state => {
            if (state.currentOrderToAuthorize &&
                state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[2].selectedItem) {
                return state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[2].selectedItem;
            } else {
                return "";
            }
        },
        getCurrentDepressionSeverity: state => {
            if (state.currentOrderToAuthorize) {
                const selected = state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[2].selectedItem;
                const severeId = state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[2].items[2].medicalNecessityQuestionGroupItemId;
                const moderateId = state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[2].items[1].medicalNecessityQuestionGroupItemId;
                const mildId = state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[2].items[0].medicalNecessityQuestionGroupItemId;

                if (selected === severeId) {
                    return Enums.DepressionSeverity.SEVERE;
                } else if (selected === moderateId) {
                    return Enums.DepressionSeverity.MODERATE;
                } else if (selected === mildId) {
                    return Enums.DepressionSeverity.MILD;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },
        getDiagCode: state => {
            const path = ["currentOrderToAuthorize", "psych", "diagnosticCodesDomainModel", "diagnosticCode"];
            const value = Common.getJsonPathValue(path, state);
            return value || {};
        },
        getSecondaryDiagCode1: state => {
            const path = ["currentOrderToAuthorize", "psych", "diagnosticCodesDomainModel", "secondaryDiagnosticCode1"];
            const value = Common.getJsonPathValue(path, state);
            return value || {};
        },
        getSecondaryDiagCode2: state => {
            const path = ["currentOrderToAuthorize", "psych", "diagnosticCodesDomainModel", "secondaryDiagnosticCode2"];
            const value = Common.getJsonPathValue(path, state);
            return value || {};
        },
        getSecondaryDiagCode3: state => {
            const path = ["currentOrderToAuthorize", "psych", "diagnosticCodesDomainModel", "secondaryDiagnosticCode3"];
            const value = Common.getJsonPathValue(path, state);
            return value || {};
        },
        getHasPatientFailedMed: state => {
            const path = ["currentOrderToAuthorize", "psych", "orderLineItemMedicalNecessityDomainModel", "groups", state.currentOrderMedicalNecessityIndex, "items", 0, "radioButtonInputValue"];
            const value = Common.getJsonPathValue(path, state);
            return value || false;
        },
        getContemplatingChange: state => {
            const path = ["currentOrderToAuthorize", "psych", "orderLineItemMedicalNecessityDomainModel", "groups", state.currentOrderMedicalNecessityIndex, "items", 1, "radioButtonInputValue"];
            const value = Common.getJsonPathValue(path, state);

            if (value) {
                return {
                    value: value,
                    unselected: false
                };
            } else {
                return {
                    value: false,
                    unselected: true
                };
            }
        },
        getFailedMeds: state => {
            const path = ["currentOrderToAuthorize", "psych", "orderLineItemMedicalNecessityDomainModel", "groups", state.currentOrderMedicalNecessityIndex, "items", 0, "textAreaResponse"];
            const value = Common.getJsonPathValue(path, state);

            if (value) {
                return value.split("|");
            } else {
                return [];
            }
        },
        getAdditionalInformation: state => {
            const path = ["currentOrderToAuthorize", "psych", "orderLineItemMedicalNecessityDomainModel", "groups", state.currentOrderMedicalNecessityIndex, "items", 2, "textAreaResponse"];
            const value = Common.getJsonPathValue(path, state);
            return value || "";
        },
        getClinicianFullName: state => {
            if (state.currentOrderToAuthorize &&
                state.currentOrderToAuthorize.clinician) {
                return state.currentOrderToAuthorize.clinician;
            } else {
                return "";
            }
        },
        getClinicianEmail: state => {
            if (state.currentOrderToAuthorize &&
                state.currentOrderToAuthorize.clinicianEmail) {
                return state.currentOrderToAuthorize.clinicianEmail;
            } else {
                return "";
            }
        },
        getClinicianSignatureFont: state => {
            if (state.currentOrderToAuthorize &&
                state.currentOrderToAuthorize.clinicianSignatureFont) {
                return Common.getGoogleFontName(state.currentOrderToAuthorize.clinicianSignatureFont);
            } else {
                return "";
            }
        },
        getClinic: state => {
            if (state.currentOrderToAuthorize &&
                state.currentOrderToAuthorize.clinic) {
                return state.currentOrderToAuthorize.clinic;
            } else {
                return "";
            }
        },
        getMtfhrSelected: state => {
            const path = ["currentOrderToAuthorize", "otherTests", 0, "selected"];
            const value = Common.getJsonPathValue(path, state);
            return value || false;
        },
        getMthfrDiagCode: state => {
            const path = ["currentOrderToAuthorize", "otherTests", 0, "diagnosticCodesDomainModel", "diagnosticCode"];
            const value = Common.getJsonPathValue(path, state);
            return value || {};
        },
        getMthfrSecondaryDiagCode1: state => {
            const path = ["currentOrderToAuthorize", "otherTests", 0, "diagnosticCodesDomainModel", "secondaryDiagnosticCode1"];
            const value = Common.getJsonPathValue(path, state);
            return value || {};
        },
        getMthfrSecondaryDiagCode2: state => {
            const path = ["currentOrderToAuthorize", "otherTests", 0, "diagnosticCodesDomainModel", "secondaryDiagnosticCode2"];
            const value = Common.getJsonPathValue(path, state);
            return value || {};
        },
        getMthfrSecondaryDiagCode3: state => {
            const path = ["currentOrderToAuthorize", "otherTests", 0, "diagnosticCodesDomainModel", "secondaryDiagnosticCode3"];
            const value = Common.getJsonPathValue(path, state);
            return value || {};
        },
        getMonsensoSelected: state => {
            if (state.currentOrderToAuthorize &&
                state.currentOrderToAuthorize.otherTests[1].selected) {
                return state.currentOrderToAuthorize.otherTests[1].selected;
            } else {
                return false;
            }
        },
        getMonsensoEmail: state => {
            if (state.currentOrderToAuthorize &&
                state.currentOrderToAuthorize.otherTests[1].monsensoDomainModel.email) {
                return state.currentOrderToAuthorize.otherTests[1].monsensoDomainModel.email;
            } else {
                return "";
            }
        },
        getPsychProductId: state => {
            const path = ["currentOrderToAuthorize", "psych", "productId"];
            const value = Common.getJsonPathValue(path, state);
            return value || "";
        },
        getMthfrProductId: state => {
            if (state.currentOrderToAuthorize &&
                state.currentOrderToAuthorize.otherTests[0].productId) {
                return state.currentOrderToAuthorize.otherTests[0].productId;
            } else {
                return "";
            }
        },
        getMthfrFolateSupplement: state => {
            const path = ["currentOrderToAuthorize", "otherTests", 0, "orderLineItemMedicalNecessityDomainModel", "groups", 0, "items", 0, "selected"];
            const value = Common.getJsonPathValue(path, state);
            return value || false;
        },
        getMthfrLowSerumLevels: state => {
            const path = ["currentOrderToAuthorize", "otherTests", 0, "orderLineItemMedicalNecessityDomainModel", "groups", 0, "items", 1, "selected"];
            const value = Common.getJsonPathValue(path, state);
            return value || false;
        },
        getMthfrOther: state => {
            const path = ["currentOrderToAuthorize", "otherTests", 0, "orderLineItemMedicalNecessityDomainModel", "groups", 0, "items", 2, "selected"];
            const value = Common.getJsonPathValue(path, state);
            return value || false;
        },
        getMthfrOtherResponse: state => {
            const path = ["currentOrderToAuthorize", "otherTests", 0, "orderLineItemMedicalNecessityDomainModel", "groups", 0, "items", 2, "textAreaResponse"];
            const value = Common.getJsonPathValue(path, state);
            return value || "";
        },
        getHasAnxiety: state => {
            const path = ["currentOrderToAuthorize", "psych", "orderLineItemMedicalNecessityDomainModel", "groups", state.currentOrderMedicalNecessityIndex - 1, "items", 0, "selected"];
            const value = Common.getJsonPathValue(path, state);
            return value || false;
        },
        getIs2020Questions: state => {
            const path = ["currentOrderToAuthorize", "psych", "orderLineItemMedicalNecessityDomainModel", "groups", 1, "name"];
            const value = Common.getJsonPathValue(path, state);
            return value === "Treatment Plan";
        },
        get2020FailedMeds: state => {
            const path = ["currentOrderToAuthorize", "psych", "orderLineItemMedicalNecessityDomainModel", "groups", 0, "items", 0, "failedMedicationsResponse"];
            const value = Common.getJsonPathValue(path, state);
            return value;
        },
        getIsConsideringAugmentingWithNewMedication: state => {
            const path = ["currentOrderToAuthorize", "psych", "orderLineItemMedicalNecessityDomainModel", "groups", 1, "items", 0, "selected"];
            const value = Common.getJsonPathValue(path, state);
            return value || false;
        },
        getAugmentationMedications: state => {
            const path = ["currentOrderToAuthorize", "psych", "orderLineItemMedicalNecessityDomainModel", "groups", 1, "items", 0, "failedMedicationsResponse"];
            const value = Common.getJsonPathValue(path, state);
            return value || false;
        },
        getIsConsideringDoseageAdjustment: state => {
            const path = ["currentOrderToAuthorize", "psych", "orderLineItemMedicalNecessityDomainModel", "groups", 1, "items", 1, "selected"];
            const value = Common.getJsonPathValue(path, state);
            return value || false;
        },
        getDoseageMedications: state => {
            const path = ["currentOrderToAuthorize", "psych", "orderLineItemMedicalNecessityDomainModel", "groups", 1, "items", 1, "failedMedicationsResponse"];
            const value = Common.getJsonPathValue(path, state);
            return value || false;
        },
        getConsideredNonGeneticFactors: state => {
            const path = ["currentOrderToAuthorize", "psych", "orderLineItemMedicalNecessityDomainModel", "groups", 2, "items", 0, "radioButtonInputValue"];
            const value = Common.getJsonPathValue(path, state);

            if (value !== null && typeof value !== "undefined") {
                return {
                    value: value,
                    unselected: false
                };
            } else {
                return {
                    value: false,
                    unselected: true
                };
            }
        },
        getAllTestsOrdered: state => {
            const path = ["currentOrderToAuthorize", "orderLineItemsDomainModel", "currentProducts"];
            const value = Common.getJsonPathValue(path, state);
            return value || [];
        }
    },
    mutations: {
        removeOrderToAuthorize(state) {
            if (state.ordersToAuthorize.length > 0) {
                state.ordersToAuthorize.shift();
            }
        },
        setPreviousGeneTypeState(state, geneState) {
            state.previousGeneTypeState = geneState;
        },
        setOrdersToAuthorize(state, orders) {
            state.ordersToAuthorize = orders;
        },
        setCurrentOrderToAuthorize(state, order) {
            state.currentOrderToAuthorize = order;
            state.currentOrderMedicalNecessityIndex = state.currentOrderToAuthorize.isMedicare ? 3 : 4;
        },
        setCurrentOrderPatientName(state, value) {
            const lastName = value.split(" ").pop();
            const firstName = value.split(" ", 1);

            state.currentOrderToAuthorize.patientFirstName = firstName;
            state.currentOrderToAuthorize.patientLastName = lastName;
        },
        setCurrentOrderPatientDob (state, value) {
            state.currentOrderToAuthorize.patientDOB = value;
        },
        setDsm5SufferFromDepression(state, boolValue) {
            const id = state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[0].items[0].medicalNecessityQuestionGroupItemId;
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[0].selectedItem = id;
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[0].items[0].radioButtonInputValue = boolValue;
        },
        setDepressiveMood(state, boolValue) {
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[1].items[0].selected = boolValue;
        },
        setDiminshedInterestActivities(state, boolValue) {
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[1].items[1].selected = boolValue;
        },
        setSignficatnWeightLoss(state, boolValue) {
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[1].items[2].selected = boolValue;
        },
        setInsomnia(state, boolValue) {
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[1].items[3].selected = boolValue;
        },
        setPsychomotor(state, boolValue) {
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[1].items[4].selected = boolValue;
        },
        setFatigue(state, boolValue) {
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[1].items[5].selected = boolValue;
        },
        setFeelingWorthless(state, boolValue) {
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[1].items[6].selected = boolValue;
        },
        setDimenishedConcentration(state, boolValue) {
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[1].items[7].selected = boolValue;
        },
        setRecurrentThoughtOfDeath(state, boolValue) {
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[1].items[8].selected = boolValue;
        },
        setMildSeverity(state, boolValue) {
            if (boolValue) {
                const mildId = state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[2].items[0].medicalNecessityQuestionGroupItemId;
                state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[2].selectedItem = mildId;
            } else {
                state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[2].selectedItem = null;
            }
        },
        setModerateSeverity(state, boolValue) {
            if (boolValue) {
                const moderateId = state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[2].items[1].medicalNecessityQuestionGroupItemId;
                state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[2].selectedItem = moderateId;
            } else {
                state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[2].selectedItem = null;
            }
        },
        setSevereSeverity(state, boolValue) {
            if (boolValue) {
                const servereId = state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[2].items[2].medicalNecessityQuestionGroupItemId;
                state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[2].selectedItem = servereId;
            } else {
                state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[2].selectedItem = null;
            }
        },
        setDiagCode(state, value) {
            state.currentOrderToAuthorize.psych.diagnosticCodesDomainModel.diagnosticCode = value;
        },
        setSecondaryDiagCode1(state, value) {
            state.currentOrderToAuthorize.psych.diagnosticCodesDomainModel.secondaryDiagnosticCode1 = value;
        },
        setSecondaryDiagCode2(state, value) {
            state.currentOrderToAuthorize.psych.diagnosticCodesDomainModel.secondaryDiagnosticCode2 = value;
        },
        setSecondaryDiagCode3(state, value) {
            state.currentOrderToAuthorize.psych.diagnosticCodesDomainModel.secondaryDiagnosticCode3 = value;
        },
        setHasPatientFailedMed(state, boolValue) {
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[state.currentOrderMedicalNecessityIndex].items[0].radioButtonInputValue = boolValue;
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[state.currentOrderMedicalNecessityIndex].items[0].selected = boolValue;
        },
        setContemplatingChange(state, boolValue) {
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[state.currentOrderMedicalNecessityIndex].items[1].radioButtonInputValue = boolValue;
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[state.currentOrderMedicalNecessityIndex].items[1].selected = boolValue;
        },
        setFailedMeds(state, stringValue) {
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[state.currentOrderMedicalNecessityIndex].items[0].textAreaResponse = stringValue;
        },
        setAdditionalInformation(state, stringValue) {
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[state.currentOrderMedicalNecessityIndex].items[2].textAreaResponse = stringValue;
        },
        setMtfhrSelected: (state, value) => {
            state.currentOrderToAuthorize.otherTests[0].selected = value;
        },
        setMthfrDiagCode(state, value) {
            state.currentOrderToAuthorize.otherTests[0].diagnosticCodesDomainModel.diagnosticCode = value;
        },
        setMthfrSecondaryDiagCode1(state, value) {
            state.currentOrderToAuthorize.otherTests[0].diagnosticCodesDomainModel.secondaryDiagnosticCode1 = value;
        },
        setMthfrSecondaryDiagCode2(state, value) {
            state.currentOrderToAuthorize.otherTests[0].diagnosticCodesDomainModel.secondaryDiagnosticCode2 = value;
        },
        setMthfrSecondaryDiagCode3(state, value) {
            state.currentOrderToAuthorize.otherTests[0].diagnosticCodesDomainModel.secondaryDiagnosticCode3 = value;
        },
        setMonsensoSelected(state, value) {
            state.currentOrderToAuthorize.otherTests[1].selected = value;
        },
        setMonsensoEmail(state, value) {
            state.currentOrderToAuthorize.otherTests[1].monsensoDomainModel.email = value;
        },
        setMthfrFolateSupplement(state, value) {
            state.currentOrderToAuthorize.otherTests[0].orderLineItemMedicalNecessityDomainModel.groups[0].items[0].selected = value;
            state.currentOrderToAuthorize.otherTests[0].orderLineItemMedicalNecessityDomainModel.groups[0].items[0].radioButtonInputValue = value;
        },
        setMthfrLowSerumLevels(state, value) {
            state.currentOrderToAuthorize.otherTests[0].orderLineItemMedicalNecessityDomainModel.groups[0].items[1].selected = value;
            state.currentOrderToAuthorize.otherTests[0].orderLineItemMedicalNecessityDomainModel.groups[0].items[1].radioButtonInputValue = value;
        },
        setMthfrOther(state, value) {
            state.currentOrderToAuthorize.otherTests[0].orderLineItemMedicalNecessityDomainModel.groups[0].items[2].selected = value;
            state.currentOrderToAuthorize.otherTests[0].orderLineItemMedicalNecessityDomainModel.groups[0].items[2].radioButtonInputValue = value;
        },
        setMthfrOtherResponse(state, value) {
            state.currentOrderToAuthorize.otherTests[0].orderLineItemMedicalNecessityDomainModel.groups[0].items[2].textAreaResponse = value;
        },
        setHasAnxiety(state, value) {
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[state.currentOrderMedicalNecessityIndex - 1].items[0].selected = value;
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[state.currentOrderMedicalNecessityIndex - 1].items[0].radioButtonInputValue = value;
        },
        set2020FailedMeds(state, value) {
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[0].items[0].failedMedicationsResponse = value;
        },
        setIsConsideringAugmentingWithNewMedication(state, value) {
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[1].items[0].selected = value;
        },
        setAugmentationMedications(state, value) {
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[1].items[0].failedMedicationsResponse = value;
        },
        setIsConsideringDoseageAdjustment(state, value) {
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[1].items[1].selected = value;
        },
        setDoseageMedications(state, value) {
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[1].items[1].failedMedicationsResponse = value;
        },
        setConsideredNonGeneticFactors(state, value) {
            state.currentOrderToAuthorize.psych.orderLineItemMedicalNecessityDomainModel.groups[2].items[0].radioButtonInputValue = value;
        }
    },
    actions: {
        removeOrderToAuthorize(context) {
            context.commit("removeOrderToAuthorize");
        },
        setPreviousGeneTypeState(context, geneState) {
            context.commit("setPreviousGeneTypeState", geneState);
        },
        setOrdersToAuthorize(context, orders) {
            context.commit("setOrdersToAuthorize", orders);
        },
        setCurrentOrderPatientName(context, value) {
            context.commit("setCurrentOrderPatientName", value);
        },
        setCurrentOrderPatientDob(context, value) {
            context.commit("setCurrentOrderPatientDob", value);
        },
        setCurrentOrderToAuthorize(context, order) {
            context.commit("setCurrentOrderToAuthorize", order);
        },
        setDsm5SufferFromDepression(context, boolValue) {
            context.commit("setDsm5SufferFromDepression", boolValue);
        },
        setDepressiveMood(context, boolValue) {
            context.commit("setDepressiveMood", boolValue);
        },
        setDiminshedInterestActivities(context, boolValue) {
            context.commit("setDiminshedInterestActivities", boolValue);
        },
        setSignficatnWeightLoss(context, boolValue) {
            context.commit("setSignficatnWeightLoss", boolValue);
        },
        setInsomnia(context, boolValue) {
            context.commit("setInsomnia", boolValue);
        },
        setPsychomotor(context, boolValue) {
            context.commit("setPsychomotor", boolValue);
        },
        setFatigue(context, boolValue) {
            context.commit("setFatigue", boolValue);
        },
        setFeelingWorthless(context, boolValue) {
            context.commit("setFeelingWorthless", boolValue);
        },
        setDimenishedConcentration(context, boolValue) {
            context.commit("setDimenishedConcentration", boolValue);
        },
        setRecurrentThoughtOfDeath(context, boolValue) {
            context.commit("setRecurrentThoughtOfDeath", boolValue);
        },
        setMildSeverity(context, boolValue) {
            context.commit("setMildSeverity", boolValue);
        },
        setModerateSeverity(context, boolValue) {
            context.commit("setModerateSeverity", boolValue);
        },
        setSevereSeverity(context, boolValue) {
            context.commit("setSevereSeverity", boolValue);
        },
        setDiagCode(context, value) {
            context.commit("setDiagCode", value);
        },
        setSecondaryDiagCode1(context, value) {
            context.commit("setSecondaryDiagCode1", value);
        },
        setSecondaryDiagCode2(context, value) {
            context.commit("setSecondaryDiagCode2", value);
        },
        setSecondaryDiagCode3(context, value) {
            context.commit("setSecondaryDiagCode3", value);
        },
        setHasPatientFailedMed(context, boolValue) {
            context.commit("setHasPatientFailedMed", boolValue);
        },
        setContemplatingChange(context, boolValue) {
            context.commit("setContemplatingChange", boolValue);
        },
        setFailedMeds(context, stringValue) {
            context.commit("setFailedMeds", stringValue);
        },
        setAdditionalInformation(context, stringValue) {
            context.commit("setAdditionalInformation", stringValue);
        },
        setMtfhrSelected(context, value) {
            context.commit("setMtfhrSelected", value);
        },
        setMthfrDiagCode(context, value) {
            context.commit("setMthfrDiagCode", value);
        },
        setMthfrSecondaryDiagCode1(context, value) {
            context.commit("setMthfrSecondaryDiagCode1", value);
        },
        setMthfrSecondaryDiagCode2(context, value) {
            context.commit("setMthfrSecondaryDiagCode2", value);
        },
        setMthfrSecondaryDiagCode3(context, value) {
            context.commit("setMthfrSecondaryDiagCode3", value);
        },
        setMonsensoSelected(context, value) {
            context.commit("setMonsensoSelected", value);
        },
        setMonsensoEmail(context, value) {
            context.commit("setMonsensoEmail", value);
        },
        setMthfrFolateSupplement(context, value) {
            context.commit("setMthfrFolateSupplement", value);
        },
        setMthfrLowSerumLevels(context, value) {
            context.commit("setMthfrLowSerumLevels", value);
        },
        setMthfrOther(context, value) {
            context.commit("setMthfrOther", value);
        },
        setMthfrOtherResponse(context, value) {
            context.commit("setMthfrOtherResponse", value);
        },
        setHasAnxiety(context, value) {
            context.commit("setHasAnxiety", value);
        },
        set2020FailedMeds(context, value) {
            context.commit("set2020FailedMeds", value);
        },
        setIsConsideringAugmentingWithNewMedication(context, value) {
            context.commit("setIsConsideringAugmentingWithNewMedication", value);
        },
        setAugmentationMedications(context, value) {
            context.commit("setAugmentationMedications", value);
        },
        setIsConsideringDoseageAdjustment(context, value) {
            context.commit("setIsConsideringDoseageAdjustment", value);
        },
        setDoseageMedications(context, value) {
            context.commit("setDoseageMedications", value);
        },
        setConsideredNonGeneticFactors(context, value) {
            context.commit("setConsideredNonGeneticFactors", value);
        }
    }
};
