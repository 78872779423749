<template>
    <div class="psychotropic-2020-container">
        <div v-if="patientEnteredInfoOpen && $store.getters.getCurrentOrderIsPatientEntered" class="patient-entered-info">
            <div class="close-btn" @click="patientEnteredInfoOpen = false">
                &#x2DF;
            </div>
            <p class="sub-section-label">
                1. Tap into the ICD-10 box to quickly find codes to match your patient's diagnosis.
            </p>
            <p class="sub-section-label">
                2. Your patient indicated these medications have not worked for them. Review, add or edit as necessary to match their medical record.
            </p>
            <p class="sub-section-label">
                3. Complete the Treatment Plan section
            </p>
        </div>
        <h2 :class="testHeaderClass">
            {{ $store.getters.getTestTitle }}
        </h2>
        <diagnosis />
        <failed-meds class="psych-spacer" />
        <treatment-plans class="psych-spacer" />
        <hr v-if="showMedicareSection" class="horizontal-rule">
        <medicare v-if="showMedicareSection" />
    </div>
</template>

<script>
import Diagnosis from "./Psychotropic/Diagnosis.vue";
import FailedMeds from "./Psychotropic/FailedMedications.vue";
import TreatmentPlans from "./Psychotropic/TreatmentPlan.vue";
import Medicare from "./Psychotropic/Medicare.vue";

export default {
    name: "Psychotropic2020",
    components: {
        Diagnosis,
        FailedMeds,
        TreatmentPlans,
        Medicare
    },
    data() {
        return {
            patientEnteredInfoOpen: true
        };
    },
    computed: {
        showMedicareSection() {
            const isTreatmentPlanSelected = this.$store.getters.getIsConsideringAugmentingWithNewMedication || this.$store.getters.getIsConsideringDoseageAdjustment;
            const isMedicare = this.$store.getters.getCurrentOrderIsMedicare;

            return isTreatmentPlanSelected && isMedicare;
        },
        testHeaderClass() {
            let headerClasses = "section-header";
            if (this.$store.getters.getTestTitle !== this.$store.getters.getDefaultTestTitle) {
                headerClasses = headerClasses + " blue-section-header";
            }

            return headerClasses;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/AuthorizeOrders.scss";

.psychotropic-2020-container {
    @include auth-section-border();
    border-radius: 5px;
    padding: 15px 7px;
}

.patient-entered-info {
    position: relative;
    top: -30px;
    left: -9px;
    width: calc(100% + 18px);
    padding: 20px 27px;
    background: #FFF58C;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    .sub-section-label {
        margin-bottom: 30px;
    }

    .sub-section-label:nth-child(4) {
        margin-bottom: 0;
    }

    .close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 2em;
        font-weight: bold;

        &:hover {
            cursor: pointer;
        }
    }
}

.patient-entered-info:after {
    content: '';
    display: block;
    position: absolute;
    left: calc(50% - 25px);
    top: 100%;
    width: 0;
    height: 0;
    border-top: 25px solid #FFF58C;
    border-right: 25px solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 25px solid transparent;
}

.psych-spacer {
    margin-top: 45px;
}

.horizontal-rule {
    margin-top: 30px;
    margin-bottom: 25px;
    height: 1px;
    background-color: #CDCDCD;
    border: 0;
}

.blue-section-header {
    color: #004A88;
}
</style>
