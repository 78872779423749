<template>
    <div class="medicare-required">
        <h4 id="medicare-required-header" class="sub-section-header">
            Required for Medicare/Medicare Advantage Coverage
        </h4>
        <div v-show="$store.getters.getIsConsideringAugmentingWithNewMedication" class="augment-container">
            <label class="sub-section-label">
                List the GeneSight medications that you are considering for <b>augmentation</b> or <b>starting/switching to</b>
            </label>
            <error-wrapper
                :error-exists="$store.getters.augmentMedicationsError"
                error-message="Please provide a response."
            >
                <div class="augmentation-multiselect-container">
                    <div class="augmentation-multiselect" :class="[isSingleGeneTest ? 'multiselect-single-gene-warning': '', (isSingleGeneTest && $store.getters.getAugmentationHighlight) ? 'multiselect-add-medication' : '']" />
                </div>
            </error-wrapper>
        </div>
        <div v-show="$store.getters.getIsConsideringDoseageAdjustment && !$store.getters.getDisableDoseageAdjustment" class="doseage-container">
            <label class="sub-section-label">
                List the GeneSight medications that you are considering for <b>dosage adjustment</b>
            </label>
            <error-wrapper
                :error-exists="$store.getters.doseageMedicationsError"
                error-message="Please provide a response."
            >
                <div class="doseage-multiselect-container">
                    <div class="doseage-multiselect" :class="[isSingleGeneTest ? 'multiselect-single-gene-warning': '', (isSingleGeneTest && $store.getters.getDoseageHighlight) ? 'multiselect-add-medication' : '']" />
                </div>
            </error-wrapper>
        </div>
        <gene-warning v-if="isZeroGeneTest" :report-link="singleGeneReportLink" class="single-gene-alert" :show-zero-gene-message="true" :is-zero-gene-test="isZeroGeneTest" />
        <gene-warning v-if="isSingleGeneTest" id="auth-single-gene-alert" :report-link="singleGeneReportLink" :single-gene-name="singleGeneName" class="single-gene-alert" :show-zero-gene-message="false" :medication-list="medicationList" :single-gene-subtext="singleGene"/>
        <label class="sub-section-label">
            Have you considered non-genetic factors to make a preliminary drug selection, including a personalized medication decision based on the patient’s diagnosis, the patient’s other medical conditions, other medications the patient is taking, professional judgment, clinical science and basic science pertinent to the drug (e.g. mechanism of action, side effects), the patient’s past medical history, and when pertinent, family history and the patient's preferences and values?
        </label>
        <error-wrapper
            :error-exists="$store.getters.nonGeneticFactorsError"
            error-message="Please provide a response."
        >
            <div class="section-check-row">
                <myriad-radio
                    id="non-genetic-yes"
                    v-model="considerNonGeneticFactors"
                    name="nonGeneticYes"
                    label="Yes"
                    class="section-radio"
                    :show-unselected="isConsiderNonGeneticFactorNotSelected"
                    :model-property="considerNonGeneticFactors"
                    @item-selected="nonGeneticFactorsDirty = true"
                />
                <myriad-radio
                    id="non-genetic-no"
                    v-model="considerNonGeneticFactors"
                    name="nonGeneticNo"
                    label="No"
                    class="section-radio"
                    :false-checkbox="true"
                    :show-unselected="isConsiderNonGeneticFactorNotSelected"
                    :model-property="considerNonGeneticFactors"
                    @item-selected="nonGeneticFactorsDirty = true"
                />
            </div>
        </error-wrapper>
        <gene-warning v-if="!considerNonGeneticFactors"
                      class="non-genetic-warning"
                      :report-link="singleGeneReportLink"
                      :show-zero-gene-message="true"
                      :is-zero-gene-test="isZeroGeneTest"
                      :is-abn-warning="true"
                      :is-multi-gene-test="isMultiGeneTest"
                      :has-considered-non-genetic-factors="considerNonGeneticFactors"
        />
    </div>
</template>

<script>
import { NeuroMultiselect } from "@myriad-neuroscience/myriad-neuro-multiselect/neuro-multiselect.js";
import MyriadRadio from "../../../MyriadRadio.vue";
import ErrorWrapper from "../../ErrorWrapper.vue";
import GeneWarning from "../Psychotropic/GeneWarning.vue";
import Common from "../../../../scripts/common.js";
import Enums from "../../../../scripts/enums.js";

export default {
    name: "MedicareRequired",
    components: {
        MyriadRadio,
        ErrorWrapper,
        GeneWarning
    },
    data() {
        return {
            nonGeneticFactors: false,
            nonGeneticFactorsDirty: false,
            augmentHeaderString: "You selected that this patient has Medicare. Medicare coverage for PGx tests is limited to when providers are considering use of one or more medications with a gene-drug interaction that is clinically actionable and designated by the FDA or CPIC (category A or B).",
            singleGeneName: "",
            augmentationGene: "",
            doseageGene: "",
            augmentationObjectList: [],
            doseageObjectList: []
        };
    },
    computed: {
        considerNonGeneticFactors: {
            get() {
                return this.$store.getters.getConsideredNonGeneticFactors.value;
            },
            set(value) {
                this.$store.dispatch("setConsideredNonGeneticFactors", value);
            }
        },
        isConsiderNonGeneticFactorNotSelected() {
            return !this.nonGeneticFactorsDirty && this.$store.getters.getConsideredNonGeneticFactors.unselected;
        },
        augmentationMedications: {
            get() {
                return this.$store.getters.getAugmentationMedications;
            },
            set(value) {
                this.$store.dispatch("setAugmentationMedications", value);
            }
        },
        doseageMedications: {
            get() {
                return this.$store.getters.getDoseageMedications;
            },
            set(value) {
                this.$store.dispatch("setDoseageMedications", value);
            }
        },
        singleGeneReportLink() {
            const configUrl = config.url;
            const baseUrl = configUrl.replace("api", "");
            const url = baseUrl + "/Order/SingleGeneSampleReport";
            return url;
        },
        isSingleGeneTest() {
            const { doseageMedsValid, augMedsValid } = this.getMedInputsValid();
            return doseageMedsValid && augMedsValid && Common.getTestType() === Enums.TestTypes.SINGLE_GENE;
        },
        isZeroGeneTest() {
            const { doseageMedsValid, augMedsValid } = this.getMedInputsValid();
            return doseageMedsValid && augMedsValid && Common.getTestType() === Enums.TestTypes.ZERO_GENE;
        },
        isMultiGeneTest() {
            const { doseageMedsValid, augMedsValid } = this.getMedInputsValid();
            return doseageMedsValid && augMedsValid && Common.getTestType() === Enums.TestTypes.MULTI_GENE;
        },
        combinedMeds() {
            return this.$store.getters.getCombinedMeds;
        },
        otherMeds() {
            return this.$store.getters.getOtherMeds;
        },
        cpicMeds() {
            return this.$store.getters.getCpicMeds;
        },
        cpicOtherMeds() {
            return [
                { "FDA & CPIC A/B Meds": this.cpicMeds },
                { "Other Meds": this.otherMeds }
            ];
        },
        medicationList() {
            var meds = [];

            if (this.$store.getters.getIsConsideringAugmentingWithNewMedication) {
                meds = [...meds, ...this.augmentationList];
            }
            if (this.$store.getters.getIsConsideringDoseageAdjustment && !this.$store.getters.getDisableDoseageAdjustment) {
                meds = [...meds, ...this.doseageList];
            }
            return meds;
        },
        singleGene() {
            if (this.$store.getters.getIsConsideringAugmentingWithNewMedication) {
                return this.augmentationGene;
            }
            if (this.$store.getters.getIsConsideringDoseageAdjustment && !this.$store.getters.getDisableDoseageAdjustment) {
                return this.doseageGene;
            }

            return "";
        },
        augmentationList: {
            get() {
                return this.augmentationObjectList;
            },
            set(value) {
                this.augmentationObjectList = value;
            }
        },
        doseageList: {
            get() {
                return this.doseageObjectList;
            },
            set(value) {
                this.doseageObjectList = value;
            }
        }
    },
    watch: {
        combinedMeds(newValues) {
            this.$nextTick(() => {
                this.createDoseageMultiselect(newValues);
            });
        },
        cpicOtherMeds(newValues) {
            this.$nextTick(() => {
                this.createAugmentationMultiselect(newValues);
            });
        },
        isSingleGeneTest(newValue) {
            if (!newValue) {
                this.disableHighlights();
            }
            this.setSingleGeneStoreInfo(newValue);
        },
        isZeroGeneTest(newValue) {
            this.$store.dispatch("setIsZeroGeneTest", newValue);
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.combinedMeds.length > 0) {
                this.createDoseageMultiselect(this.combinedMeds);
            }

            if (this.cpicMeds.length > 0 && this.otherMeds.length > 0) {
                this.createAugmentationMultiselect(this.cpicOtherMeds);
            }
        });
        this.setSingleGeneStoreInfo(this.isSingleGeneTest);
    },
    methods: {
        createAugmentationMultiselect(medValues) {
            const domElement = document.querySelector(".augmentation-multiselect");

            if (domElement === null) {
                return;
            }

            domElement.innerHTML = "";

            let previousAugmentMultiselect = this.$store.getters.getAugmentationMultiselect;
            const augmentMultiselect = new NeuroMultiselect();
            if (previousAugmentMultiselect === null || previousAugmentMultiselect === undefined) {
                previousAugmentMultiselect = augmentMultiselect;
            }
            this.setPreviousGeneState(augmentMultiselect, previousAugmentMultiselect.state.previousGeneState);

            augmentMultiselect.init(
                domElement,
                medValues,
                this.initAugmentationList(medValues),
                {
                    allowCustomInputs: false,
                    headerString: this.augmentHeaderString,
                    placeholderString: "Type or select a medication.",
                    footerString: "The same medication cannot be selected for augmentation and dosage adjustment.",
                    showSubTextOnSelectedItem: true
                }
            );

            this.getAugmentationSingleGene(medValues, this.augmentationMedications);

            const that = this;
            augmentMultiselect.setupCallbackReturn(function(selectedValues) {
                that.$store.dispatch("setAugmentationHighlight", false);
                that.$store.dispatch("setDoseageHighlight", false);
                const stringArray = [];
                var augmentationList = [];
                that.augmentationGene = '';
                selectedValues.forEach((value) => {
                    stringArray.push(value.value);
                    if (value.subText) {
                        that.augmentationGene = value.subText; // this value won't be used unless single gene
                        augmentationList.push(value);
                    }
                });

                that.augmentationMedications = stringArray;
                that.augmentationList = augmentationList;

                const doseageMultiselect = that.$store.getters.getDoseageMultiselect;
                const copyValues = JSON.parse(JSON.stringify(selectedValues));
                doseageMultiselect.markValuesAsDisabled(copyValues);
            });

            augmentMultiselect.setupBlurCallback(function() {
                Common.trackUserBehaviorInResponseToGeneWarnings();
            });

            this.$store.dispatch("setAugmentationMultiselect", augmentMultiselect);
        },
        createDoseageMultiselect(medValues) {
            const domElement = document.querySelector(".doseage-multiselect");
            const failedMeds = this.$store.getters.getFailedMedsMultiselect.state.selectedValues;

            if (domElement === null) {
                return;
            }

            domElement.innerHTML = "";

            let previousDoseageMultiselect = this.$store.getters.getDoseageMultiselect;
            const doseageMultiselect = new NeuroMultiselect();
            if (previousDoseageMultiselect === null || previousDoseageMultiselect === undefined) {
                previousDoseageMultiselect = doseageMultiselect;
            }
            this.setPreviousGeneState(doseageMultiselect, previousDoseageMultiselect.state.previousGeneState);
            doseageMultiselect.AvailableOptions = medValues;
            doseageMultiselect.applyMatchingSubTextFromAvailableOptions(failedMeds);
            doseageMultiselect.init(
                domElement,
                failedMeds,
                this.initDoseageList(failedMeds),
                {
                    allowCustomInputs: false,
                    placeholderString: "Type or select a medication.",
                    footerString: "Only medication(s) in the Failed Medications section above can be considered for dosage adjustment. The same medication cannot be selected for augmentation and dosage adjustment.",
                    showSubTextOnSelectedItem: true
                }
            );

            this.getDoseageSingleGene(failedMeds, this.doseageMedications);

            const that = this;
            doseageMultiselect.setupCallbackReturn(function(selectedValues) {
                that.$store.dispatch("setDoseageHighlight", false);
                that.$store.dispatch("setAugmentationHighlight", false);
                const stringArray = [];
                var doseageList = [];
                that.doseageGene = "";
                selectedValues.forEach((value) => {
                    stringArray.push(value.value);
                    if (value.subText) {
                        that.doseageGene = value.subText; // this value won't be used unless single gene
                        doseageList.push(value);
                    }
                });

                that.doseageMedications = stringArray;
                that.doseageList = doseageList;

                const augmentationMultiselect = that.$store.getters.getAugmentationMultiselect;
                const copyValues = JSON.parse(JSON.stringify(selectedValues));
                augmentationMultiselect.markValuesAsDisabled(copyValues);
            });

            doseageMultiselect.setupBlurCallback(function() {
                Common.trackUserBehaviorInResponseToGeneWarnings();
            });

            this.$store.dispatch("setDoseageMultiselect", doseageMultiselect);
        },
        setPreviousGeneState(multiselect, previousGeneState) {
            if (previousGeneState != null || previousGeneState !== undefined) {
                multiselect.state.previousGeneState = previousGeneState;
            } else {
                if (this.isMultiGeneTest) {
                    multiselect.state.previousGeneState = Enums.TestTypes.MULTI_GENE;
                } else if (this.isSingleGeneTest) {
                    multiselect.state.previousGeneState = Enums.TestTypes.SINGLE_GENE;
                } else if (this.isZeroGeneTest) {
                    multiselect.state.previousGeneState = Enums.TestTypes.ZERO_GENE;
                }
            }
        },
        getMedInputsValid() {
            const doseageMedsSelected = this.$store.getters.getIsConsideringDoseageAdjustment;
            const doseageMedsAnswered = this.doseageMedications.length > 0;

            const augMedsSelected = this.$store.getters.getIsConsideringAugmentingWithNewMedication;
            const augMedsAnswered = this.augmentationMedications.length > 0;

            const doseageMedsValid = doseageMedsSelected ? doseageMedsAnswered : true;
            const augMedsValid = augMedsSelected ? augMedsAnswered : true;

            return {
                doseageMedsValid: doseageMedsValid,
                augMedsValid: augMedsValid
            };
        },
        setSingleGeneStoreInfo(newValue) {
            if (newValue) {
                const testTitle = this.singleGeneName + " Single Gene Report";
                this.$store.dispatch("setTestTitle", testTitle);
            } else {
                this.$store.dispatch("resetTestTitle");
            }
            this.$store.dispatch("setIsSingleGeneTest", newValue);
        },
        getAugmentationSingleGene(allMeds, medList) {
            if ((allMeds || []).length === 0 || (medList || []).length === 0) {
                return "";
            }
            var meds = [];
            allMeds.forEach((med) => {
                var keyNames = Object.keys(med);
                if (keyNames.find((prop) => { return prop === 'FDA & CPIC A/B Meds'; })) {
                    meds = med['FDA & CPIC A/B Meds'];
                }
            });

            meds.forEach((med) => {
                if (med.value === medList[0]) {
                    this.augmentationGene = med.subText; // this value won't be used unless single gene
                }
            });
        },
        getDoseageSingleGene(allMeds, medList) {
            if ((allMeds || []).length === 0 || (medList || []).length === 0) {
                return "";
            }
            var keyNames = Object.keys(allMeds[0]);
            if (keyNames.find((prop) => { return prop === 'subText'; })) {
                allMeds.forEach((med) => {
                    if (med.value === medList[0]) {
                        this.doseageGene = med.subText; // this value won't be used unless single gene
                    }
                });
            }
        },
        initAugmentationList(medValues) {
            var allMeds = [];
            var augmentationList = [];
            this.augmentationMedications.forEach((m1) => {
                medValues.forEach((medArr) => {
                    if (medArr['FDA & CPIC A/B Meds']) {
                        medArr['FDA & CPIC A/B Meds'].forEach((m2) => {
                            if (m1 === m2.value) {
                                allMeds.push(m2);
                                if (m2.subText) {
                                    augmentationList.push(m2);
                                }
                            }
                        });
                    } else if (medArr['Other Meds']) {
                        medArr['Other Meds'].forEach((m2) => {
                            if (m1 === m2) {
                                allMeds.push(m2);
                            }
                        });
                    }
                });
            });
            this.augmentationList = augmentationList;
            return allMeds;
        },
        initDoseageList(failedMeds) {
            var allMeds = [];
            var doseageList = [];
            this.doseageMedications.forEach((m1) => {
                failedMeds.forEach((m2) => {
                    if (m1 === m2.value) {
                        allMeds.push(m2);
                        if (m2.subText) {
                            doseageList.push(m2);
                        }
                    }
                });
            });
            this.doseageList = doseageList;
            return allMeds;
        },
        disableHighlights(show) {
            this.$store.dispatch("setAugmentationHighlight", false);
            this.$store.dispatch("setDoseageHighlight", false);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~@myriad-neuroscience/myriad-neuro-multiselect/neuro-multiselect.css";
@import "../../../../styles/AuthorizeOrders.scss";

.doseage-container,
.augment-container
 {
    margin-bottom: 35px;
}

.non-genetic-warning {
    margin-top: 30px;
}

.multiselect-single-gene-warning >>> .myriad-neuro-selected-input-container {
    border: 2px solid #ff6b0b !important;
}

.multiselect-add-medication >>> .myriad-neuro-selected-input-container,
.multiselect-add-medication >>> input[type=text].myriad-neuro-multiselect-input {
    background-color: #FFF0E7 !important;
}
</style>
