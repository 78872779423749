import { BlackGrayWarningsEnum } from "../../scripts/enums.js";

export default {
    state: {
        isSingleGeneTest: false,
        isZeroGeneTest: false,
        augmentationHighlight: false,
        doseageHighlight: false
    },
    getters: {
        atLeastOneDiagCodeSupplied: (state, getters) => {
            const diagCode1Suppled = getters.getDiagCode.diagnosticCodeValue;
            const diagCode2Supplied = getters.getSecondaryDiagCode1.diagnosticCodeValue;
            const diagCode3Supplied = getters.getSecondaryDiagCode2.diagnosticCodeValue;
            const diagCode4Supplied = getters.getSecondaryDiagCode3.diagnosticCodeValue;
            const anyDiagCode = (diagCode1Suppled && typeof diagCode1Suppled !== "undefined") ||
                (diagCode2Supplied && typeof diagCode1Suppled !== "undefined") ||
                (diagCode3Supplied && typeof diagCode1Suppled !== "undefined") ||
                (diagCode4Supplied && typeof diagCode1Suppled !== "undefined");

            return anyDiagCode;
        },
        failedMedsSupplied: (state, getters) => {
            return getters.get2020FailedMeds ? getters.get2020FailedMeds.length > 0 : false;
        },
        augmentMedicationsError: (state, getters) => {
            const consideringDoseage = getters.getIsConsideringAugmentingWithNewMedication;
            const isMedicare = getters.getCurrentOrderIsMedicare;
            const atLeastOneMed = getters.getAugmentationMedications.length <= 0;

            return consideringDoseage && isMedicare && atLeastOneMed;
        },
        doseageMedicationsError: (state, getters) => {
            const consideringDoseage = getters.getIsConsideringDoseageAdjustment;
            const isMedicare = getters.getCurrentOrderIsMedicare;
            const atLeastOneMed = getters.getDoseageMedications.length <= 0;

            return consideringDoseage && isMedicare && atLeastOneMed;
        },
        nonGeneticFactorsError: (state, getters) => {
            const isMedicare = getters.getCurrentOrderIsMedicare;
            return getters.getConsideredNonGeneticFactors.unselected && isMedicare;
        },
        isAugmentOrDoseageTreatmentPlanSelected: (state, getters) => {
            const augmentSelectet = getters.getIsConsideringAugmentingWithNewMedication;
            const doseageSelected = getters.getIsConsideringDoseageAdjustment;
            return augmentSelectet || doseageSelected;
        },
        atLeastOneMthfrDiagCodeSupplied: (state, getters) => {
            const diagCode1Suppled = getters.getMthfrDiagCode.diagnosticCodeValue;
            const diagCode2Supplied = getters.getMthfrSecondaryDiagCode1.diagnosticCodeValue;
            const diagCode3Supplied = getters.getMthfrSecondaryDiagCode2.diagnosticCodeValue;
            const diagCode4Supplied = getters.getMthfrSecondaryDiagCode3.diagnosticCodeValue;
            const anyDiagCode = (diagCode1Suppled && typeof diagCode1Suppled !== "undefined") ||
                (diagCode2Supplied && typeof diagCode1Suppled !== "undefined") ||
                (diagCode3Supplied && typeof diagCode1Suppled !== "undefined") ||
                (diagCode4Supplied && typeof diagCode1Suppled !== "undefined");
            const mthfrAdded = getters.getMtfhrSelected;

            return mthfrAdded && anyDiagCode;
        },
        failedMedsGrayBlackListError: (state, getters) => {
            const failedMeds = getters.get2020FailedMeds ? getters.get2020FailedMeds : [];
            const blackList = getters.getBlackList;
            const grayList = getters.getGrayList;

            const blackMatches = failedMeds.filter((med) => {
                const failedMed = med.replace(/[^A-Za-z0-9 ]/g, "").toLowerCase();

                let shouldReturn = false;
                for (let i = 0; i < blackList.length; i++) {
                    if (failedMed === blackList[i].toLowerCase() || failedMed === "") {
                        shouldReturn = true;
                        break;
                    }
                }
                return shouldReturn;
            });

            const grayMatches = failedMeds.filter((med) => {
                const failedMed = med.replace(/[^A-Za-z0-9 ]/g, "").toLowerCase();

                let shouldReturn = false;
                for (let i = 0; i < grayList.length; i++) {
                    if (failedMed === grayList[i].toLowerCase()) {
                        shouldReturn = true;
                        break;
                    }
                }
                return shouldReturn;
            });

            const totalBlackAndGray = blackMatches.length + grayMatches.length;
            const blackListError = (failedMeds.length === totalBlackAndGray) && blackMatches.length > 0;
            const grayListWarning = (failedMeds.length === totalBlackAndGray) && grayMatches.length > 0;

            if (blackListError && !grayListWarning) {
                return BlackGrayWarningsEnum.ERROR;
            } else if (grayListWarning) {
                return BlackGrayWarningsEnum.WARNING;
            } else {
                return BlackGrayWarningsEnum.NONE;
            }
        },
        geneWarningMessageVisible: (state) => {
            return state.isZeroGeneTest;
        },
        getIsSingleGeneTest: (state) => {
            return state.isSingleGeneTest;
        },
        getIsAttestation: (state, getters) => {
            return getters.getConsideredNonGeneticFactors.value;
        },
        getAugmentationHighlight: (state) => {
            return state.augmentationHighlight;
        },
        getDoseageHighlight: (state) => {
            return state.doseageHighlight;
        }
    },
    mutations: {
        setIsSingleGeneTest(state, value) {
            state.isSingleGeneTest = value;
        },
        setIsZeroGeneTest(state, value) {
            state.isZeroGeneTest = value;
        },
        setAugmentationHighlight(state, value) {
            state.augmentationHighlight = value;
        },
        setDoseageHighlight(state, value) {
            state.doseageHighlight = value;
        }
    },
    actions: {
        setIsSingleGeneTest(context, value) {
            context.commit("setIsSingleGeneTest", value);
        },
        setIsZeroGeneTest(context, value) {
            context.commit("setIsZeroGeneTest", value);
        },
        setAugmentationHighlight(context, value) {
            context.commit("setAugmentationHighlight", value);
        },
        setDoseageHighlight(context, value) {
            context.commit("setDoseageHighlight", value);
        }
    }
};
