<template>
    <div class="authorize-orders">
        <transition>
            <error v-if="showErrorMessage" />
        </transition>
        <transition>
            <no-orders v-if="showNoOrdersPage" />
        </transition>
        <transition>
            <all-done v-if="showAllDonePage" />
        </transition>
        <div v-if="!showAllDonePage && !showNoOrdersPage && !showErrorMessage">
            <h4 class="authorize-order-header">
                Authorize Order
            </h4>
            <h6 class="authorize-order-sub-header">
                Order Number {{ orderNumber }}
            </h6>
            <div class="authorize-order-blue-notice">
                If necessary, <b>edit the response below</b> to ensure their accuracy, then <b>click the "Sign and Authorize Order" button.</b>
            </div>
            <clinican-auth-container
                v-model="patientInformationAuthModel"
                :auth-enabled="$store.getters.getPatientInformationErrors.length <= 0"
            >
                <div v-if="$store.getters.getCurrentOrderIsPatientEntered && !$store.getters.getIs2020Questions" class="auth-section-blue-topper">
                    Acknowledge that this is your patient and review the PHQ-9 results.
                </div>
                <patient-information
                    :initial-patient-name="initialPatientName"
                    :initial-patient-dob="initialPatientDob"
                />
                <phq9-results v-if="$store.getters.getCurrentOrderIsPatientEntered" />
            </clinican-auth-container>
            <auth2020-questions v-if="$store.getters.getIs2020Questions" />
            <auth2019-questions v-else />
            <div>
                <signature-authorization
                    :errors-on-page="shouldDisableAuthButton"
                    @authorize-order="tryAuthorizeOrder"
                >
                    <span v-if="getAny2020Errors">
                        At least one section above is not completed or approved. Please scroll up and complete/approve all sections.
                    </span>
                    <span v-else-if="$store.getters.geneWarningMessageVisible">
                        A patient-signed ABN is needed before this order can be authorized.  To upload a patient-signed ABN, access myGeneSight.com via a desktop or laptop computer and click the "Incomplete" link for this order on the home page and navigate to the Test page.
                    </span>
                </signature-authorization>
            </div>
        </div>
    </div>
</template>

<script>

import SignatureAuthorization from "../components/AuthorizeOrders/SignatureAuthorize.vue";
import AllDone from "../components/AuthorizeOrders/AllDone.vue";
import NoOrders from "../components/AuthorizeOrders/NoOrders.vue";
import Error from "../components/AuthorizeOrders/Error.vue";
import PatientInformation from "../components/AuthorizeOrders/PatientInformation.vue";
import ClinicanAuthContainer from "../components/AuthorizeOrders/2019/ClinicianAuthContainer.vue";
import Phq9Results from "../components/AuthorizeOrders/Phg9Results.vue";
import Auth2019Questions from "../components/AuthorizeOrders/2019/Auth2019Questions.vue";
import Auth2020Questions from "../components/AuthorizeOrders/2020/Auth2020Questions.vue";

import AuthorizationHttp from "../scripts/http/authorization-http.js";
import Enums, { BlackGrayWarningsEnum } from "../scripts/enums.js";
import Common from "../scripts/common.js";
import Axios from "axios";

export default {
    name: "AuthorizeOrders",
    components: {
        SignatureAuthorization,
        AllDone,
        Error,
        NoOrders,
        PatientInformation,
        ClinicanAuthContainer,
        Phq9Results,
        Auth2019Questions,
        Auth2020Questions
    },
    data() {
        return {
            orderId: "",
            orderNumber: null,
            initialPatientName: "",
            initialPatientDob: "",
            showAllDonePage: false,
            showNoOrdersPage: false,
            showButtonLoader: false,
            showErrorMessage: false,
            Enums: Enums
        };
    },
    computed: {
        patientInformationAuthModel: {
            get() {
                return this.$store.getters.getPatientInformationApproved;
            },
            set(value) {
                this.$store.dispatch("setPatientInformationApproved", value);
            }
        },
        shouldDisableAuthButton() {
            if (this.$store.getters.getIs2020Questions) {
                const any2020Errors = this.getAny2020Errors;
                const isGeneMessageVisible = this.$store.getters.geneWarningMessageVisible || !this.$store.getters.getIsAttestation;

                return any2020Errors || isGeneMessageVisible;
            } else {
                return this.$store.getters.getAnyErrorsExist || (!this.$store.getters.getIsAllSectionsClinicianAuthorized && this.$store.getters.getCurrentOrderIsPatientEntered);
            }
        },
        getAny2020Errors() {
            const atLeastOneDiagCodeSupplied = this.$store.getters.atLeastOneDiagCodeSupplied;
            const failedMedsSupplied = this.$store.getters.failedMedsSupplied;
            const failedMedsBlackListError = this.$store.getters.failedMedsGrayBlackListError === BlackGrayWarningsEnum.ERROR;
            const augmentMedicationsError = this.$store.getters.augmentMedicationsError;
            const doseageMedicationsError = this.$store.getters.doseageMedicationsError;
            const mthfrError = !this.$store.getters.atLeastOneMthfrDiagCodeSupplied && this.$store.getters.getMtfhrSelected;
            const isNonGeneticsNotAnswered = this.$store.getters.nonGeneticFactorsError;
            const isAugmentOrDoseageTreatmentPlanSelected = this.$store.getters.isAugmentOrDoseageTreatmentPlanSelected;

            return !atLeastOneDiagCodeSupplied || !failedMedsSupplied || augmentMedicationsError || doseageMedicationsError || failedMedsBlackListError || mthfrError || isNonGeneticsNotAnswered || !isAugmentOrDoseageTreatmentPlanSelected;
        }
    },
    watch: {
        showAllDonePage(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }
        }
    },
    created() {
        if (this.$store.getters.getOrdersToAuthorize.length > 0) {
            this.orderId = this.$store.getters.getOrdersToAuthorize[0];
            this.getAuthPageData();
        } else {
            this.showNoOrdersPage = true;
        }
    },
    methods: {
        tryAuthorizeOrder() {
            this.$store.dispatch("showFullScreenLoader");
            var postData = Common.getCurrentOrderToAuthorize();
            AuthorizationHttp.AuthorizeOrder(postData).then(() => {
                this.$store.dispatch("removeOrderToAuthorize");
                this.$store.dispatch("resetClinicianApprovedSections");

                if (this.$store.getters.getOrdersToAuthorize.length > 0) {
                    this.orderId = this.$store.getters.getOrdersToAuthorize[0];
                    this.getAuthPageData();
                    window.scrollTo(0, 0);
                } else {
                    this.$store.dispatch("setCurrentOrderToAuthorize", {});
                    this.showAllDonePage = true;
                }
            }).catch(() => {
                this.$store.dispatch("showDefaultNotificationRibbon");
            }).finally(() => {
                this.$store.dispatch("hideFullScreenLoader");
            });
        },
        getAuthPageData() {
            this.$store.dispatch("showFullScreenLoaderImmediately");
            Axios.all([
                AuthorizationHttp.GetDiagnosticCodeAutocompleteInfo(),
                AuthorizationHttp.GetDataToAuthorizeOrder({ id: this.orderId }),
                AuthorizationHttp.GetMedicationLists(),
                AuthorizationHttp.GetTreatmentMedications()]
            ).then(
                Axios.spread((diagResponse, authReponse, medsResponse, treatmentMedsResponse) => {
                    this.orderNumber = authReponse.data.orderNumber;
                    this.$store.dispatch("setCurrentOrderToAuthorize", authReponse.data);
                    this.$store.dispatch("setDefaultDiagnosticCodes", diagResponse.data);

                    this.$store.dispatch("setAllMeds", medsResponse.data.medicationList);
                    this.$store.dispatch("setBlackList", medsResponse.data.blackList);
                    this.$store.dispatch("setGrayList", medsResponse.data.greyList);

                    this.$store.dispatch("setCombinedMeds", JSON.parse(treatmentMedsResponse.data.combinedMedicationList));
                    this.$store.dispatch("setOtherMeds", treatmentMedsResponse.data.otherMedicationList);
                    this.$store.dispatch("setCpicMeds", JSON.parse(treatmentMedsResponse.data.cpicMedicationList));

                    this.initialPatientName = this.$store.getters.getCurrentOrderPatientName;
                    this.initialPatientDob = this.$store.getters.getCurrentOrderPatientDob;

                    if (this.$store.getters.getCurrentOrderIsPatientEntered) {
                        this.$store.dispatch("setPhq9Data", authReponse.data);
                    }
                })
            ).catch(() => {
                this.showErrorMessage = true;
            }).finally(() => {
                this.$store.dispatch("hideFullScreenLoader");
            });
        }
    }
};
</script>

<style lang="scss" scoped>
    @import "../styles/AuthorizeOrders.scss";

    .authorize-orders {
        padding: 30px $auth-order-side-padding;
    }

    .authorize-order-header {
        margin-bottom: 5px;
        font-size: $header-font-size;
    }

    .authorize-order-sub-header {
        margin-bottom: 20px;
        font-size: $regular-copy-size;
    }

    .authorize-section {
        padding: 17px $auth-section-side-padding;
    }

    ::v-deep .section-border {
        margin-bottom: 20px;
        border: 2px solid $border-dark-gray;
        border-radius: 10px;
    }

    ::v-deep .auth-section-blue-topper {
        padding: 10px 0;
        background-color: $authorize-blue-topper-bg;
        text-align: center;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
    }

    #psych-diag-codes {
        margin-top: 45px;
    }

    ::v-deep #psych-diag-codes .section-gray-text {
        margin-top: 0;
    }

    .authorize-order-blue-notice {
        margin-bottom: 20px;
        padding: 12px;
        text-align: center;
        border-radius: 5px;
        background: $genesight-light-blue;
    }
</style>
